<template>
  <div
    id="app"
    :class="{ 'has-mouse': hasMouse }"
    @touchstart="hasMouse = false"
  >
    <Flipbook
      class="flipbook"
      :pages="pages"
      :pagesHiRes="pagesHiRes"
      :startPage="pageNum"
      :zooms="zooms"
      v-slot="flipbook"
      ref="flipbook"
      @flip-left-start="onFlipLeftStart"
      @flip-left-end="onFlipLeftEnd"
      @flip-right-start="onFlipRightStart"
      @flip-right-end="onFlipRightEnd"
      @zoom-start="onZoomStart"
      @zoom-end="onZoomEnd"
      ambient="0.2"
      gloss="0.5"
      nPolygons="20"
    >
      <div class="action-bar">
        <left-icon
          class="btn left"
          :class="{ disabled: !flipbook.canFlipLeft }"
          @click="flipbook.flipLeft"
        />
        <plus-icon
          class="btn plus"
          :class="{ disabled: !flipbook.canZoomIn }"
          @click="flipbook.zoomIn"
        />
        <span class="page-num">
          Page {{ flipbook.page }} of {{ flipbook.numPages }}
        </span>
        <minus-icon
          class="btn minus"
          :class="{ disabled: !flipbook.canZoomOut }"
          @click="flipbook.zoomOut"
        />
        <right-icon
          class="btn right"
          :class="{ disabled: !flipbook.canFlipRight }"
          @click="flipbook.flipRight"
        />
        <select class="language-switcher" v-model="currentLanguage" @change="changeLanguage">
          <option value="en">English</option>
          <option value="fr">French</option>
          <option value="es">Spanish</option>
        </select>
      </div>
    </Flipbook>
  </div>
</template>

<script>
import 'vue-material-design-icons/styles.css'
import LeftIcon from 'vue-material-design-icons/ChevronLeftCircle'
import RightIcon from 'vue-material-design-icons/ChevronRightCircle'
import PlusIcon from 'vue-material-design-icons/PlusCircle'
import MinusIcon from 'vue-material-design-icons/MinusCircle'
import Flipbook from 'flipbook-vue'

export default {
  components: { Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon },
  data() {
    return {
      pages: [],
      pagesHiRes: [],
      hasMouse: true,
      pageNum: null,
      currentLanguage: 'en',
      zooms: [1,2]
    }
  },
  methods: {
    onFlipLeftStart(page) { console.log('flip-left-start', page) },
    onFlipLeftEnd(page) {
      console.log('flip-left-end', page)
      window.location.hash = '#' + page
    },
    onFlipRightStart(page) { console.log('flip-right-start', page) },
    onFlipRightEnd(page) {
      console.log('flip-right-end', page)
      window.location.hash = '#' + page
    },
    onZoomStart(zoom) {
      console.log('zoom-start', zoom)
    },
    onZoomEnd(zoom) {
      console.log('zoom-end', zoom)
    },
    setPageFromHash() {
      const n = parseInt(window.location.hash.slice(1), 10)
      if (isFinite(n)) this.pageNum = n
    },
    changeLanguage() {
      this.loadPages(this.currentLanguage)
    },
    loadPages(lang) {
      this.pages = [
        null,
        `images/${lang}/1.png`,
        `images/${lang}/2.png`,
        `images/${lang}/3.png`,
        `images/${lang}/4.png`,
        `images/${lang}/5.png`,
        `images/${lang}/6.png`,
      ]
      this.pagesHiRes = [
        null,
        `images-large/${lang}/1.png`,
        `images-large/${lang}/2.png`,
        `images-large/${lang}/3.png`,
        `images-large/${lang}/4.png`,
        `images-large/${lang}/5.png`,
        `images-large/${lang}/6.png`,
      ]
    },
  },
  mounted() {
    window.addEventListener('keydown', (ev) => {
      const flipbook = this.$refs.flipbook
      if (!flipbook) return
      if (ev.keyCode == 37 && flipbook.canFlipLeft) flipbook.flipLeft()
      if (ev.keyCode == 39 && flipbook.canFlipRight) flipbook.flipRight()
    })

    this.loadPages(this.currentLanguage)

    // Simulate asynchronous pages initialization
    /*setTimeout(() => {
      this.pages = [
        null,
        'images/en/1.png',
        'images/en/2.png',
        'images/en/3.png',
        'images/en/4.png',
        'images/en/5.png',
        'images/en/6.png',
      ]
      this.pagesHiRes = [
        null,
        'images-large/en/1.png',
        'images-large/en/2.png',
        'images-large/en/3.png',
        'images-large/en/4.png',
        'images-large/en/5.png',
        'images-large/en/6.png',
      ]
    }, 0)*/

    window.addEventListener('hashchange', this.setPageFromHash)
    this.setPageFromHash()
  },
}
</script>

<style>
/* 
bupa blue #0079c8 
bupa beige #f1efeb
white
black
*/


html, body {
  margin: 0;
  padding: 0;
}

#app {
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;

  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1efeb;
  color: #ccc;
  overflow: hidden;
}

a {
  color: inherit;
}

.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}

.action-bar .btn {
  font-size: 30px;
  color: #999;
  color: #0079c8;
}

.action-bar .btn svg {
  bottom: 0;
}

.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}

.has-mouse .action-bar .btn:hover {
  color: #0079c8;
  /*filter: drop-shadow(1px 1px 5px #000);*/
  cursor: pointer;
}

.action-bar .btn:active {
  filter: none !important;
}

.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}

.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
}

.flipbook .viewport {
  width: 90vw !important;
  height: calc(100vh - 50px - 40px) !important;
}

.flipbook .bounding-box {
  /*box-shadow: 0 0 20px #000;*/
  box-shadow: 0 0 10px #b7b6b3;
}

.language-switcher {
  margin-left: 1em;
  /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
  padding: 0.5em;
}
select, option {

  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: 1.3em;
}

</style>
